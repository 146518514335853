/* ==========================================================================
   Big Screen 1200px
   ========================================================================== */
.tablet {display:none;}
.mobile {display:none;}
.desktop {display: inherit;}


.section {
	padding: 30px 0;
	margin: 30px 0;
}

.header, .hero {
	padding: 0;
	margin: 0;
}

.header {
	border-bottom: 8px solid $primary-light;
	text-align: center;
}

.call {
	margin: 24px 0;	
	font-size: 0.9em;
	span {
	font-size: 1.5em;
	}
	span:before {
		content: 'Telefon: ';
	}
}
.logo {
	display: block;
	height: 80px;
	background: url(../images/hno-praxis-fulda.jpg) center center no-repeat;
	margin: 20px 0;
}

@media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
.logo { 
   background-image:url(../images/hno-praxis-fulda_x2.jpg);
   background-size: 157px 80px; }
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.headline {
	text-align: center;
	padding-bottom: 0;
	margin-bottom: -50px;
}

.text-img {
		img {
		margin-bottom: 20px;
	}

}


.highlight {
	background: #ececec;
}

.sidebar {
	h2 {
		font-size: 1.4em;
	}
	h3 {
		margin-top: 20px;
		font-size: 1em;
		line-height: 1.4em;
	}	
	p {
		font-size: 1em;
		line-height: 1.2em;
	}
	
}

.news {
	a {
		font-weight: 400;
		color: $secundary-dark;
	}	
	.link {
		font-size: 0.8em;
		line-height: 1em;		
	}
}

.map {
	.c12 {
		padding: 0;
	}
	iframe {
		width: 100%;
		height: 400px;
		margin: 0;
		padding: 0;
	} 
}


.divide {
	padding: 0;
}
hr {
	color: #ececec;	
}

.button a, .button button {
	background: #fff;	
	text-transform: uppercase;
	display: inline-block;
	padding: 10px 30px;
	border: 1px solid;
	margin: 10px 0;
}

.button a:hover, .button button:hover {
	text-decoration: none;
	background: $primary-light;
	color: #fff;	
}


.footer {
	margin-top: 40px;
	text-align: center;
	background: #ececec;
	border-top: 4px solid $primary-light;
.text-img {
	margin: 0;
	padding: 40px 0;
	}
}

#overlay {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:1000;
	background: rgba(0,0,0,0.8);
	text-align: center;
	padding-top: 50px;
	p {
		color: #fff;
	}
	.button {
		cursor: pointer;
	}
}