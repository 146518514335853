@media handheld, only screen and (max-width: 768px), only screen and (max-device-width: 768px) and (orientation:portrait){
body{font-size:88%;}



.c1,.c2,.c3,.c4,.c5,.c6,.c7,.c8,.c9,.c10,.c11,.c12{width:100%;margin-right:0;margin-left:0;padding:20px;}
.row > div:last-child {margin-bottom:0 !important;}
.desktop {display: none;}
.tablet {display: inherit;}

.header .c3 {
	padding-bottom: 10px;
}
.logo {
	margin: 0;
}
.nav {
	padding: 0;
	margin-top: 10px;
}

.call {
	margin-top: 0;
	font-size: 1.1em;
	span {
		display: inline;
		font-size: 1em;
	}
}

.sf-menu > li {
		display: inline-block;
		float: none;
	}
.sf-menu a, .sf-menu a:first-child {
	border: none;
}

.sf-menu a {
	background: #ececec;
	height: 40px;
	line-height: 40px;
	margin: 0;
	margin-top: 10px;
}


.content .c9, .content .c3 {
	padding: 0;
}



}