$primary-light: #bbd700;
$primary-dark: #85b400;
$secundary-dark: #676766;

body {
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
	font-size: 100%;
	font-size: 1em;
	color: $secundary-dark;
}



h1, h2, h3, h4 {
	font-weight: 400;
	text-transform: uppercase;
	margin:0;
	padding: 0;
	line-height: auto;
	color: $primary-dark;
}

h1 {
	font-size: 2em;
	line-height: 1.5em;
}

h1 .byline {
	display: block;
	color: $secundary-dark;
	font-size: 0.8em;
	line-height: 1em;
}



h2 {
	font-size: 1.4em;
	line-height: 1.4em;
	margin-top: 40px;
}


h2:first-child {
	margin-top: 0;
}

h3 {
	font-size: 1em;
	line-height: 1.1em;
	margin-top: 15px;
}

h3:first-child {
	margin-top: 0;
}


p {
	margin:0;
	padding:0;
	margin-top: 5px;
}

strong {
	font-weight: 600;
}

a {
	font-weight: 600;
	color: $primary-dark;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

//owl.carousel fix
.disabled {
	display: none;
}

.text-center {
	text-align: center;
}