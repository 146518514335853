/*** ESSENTIAL STYLES ***/
.sf-menu, .sf-menu * {
	margin: 0;
	padding: 0;
	list-style: none;
}
.sf-menu li {
	position: relative;
}
.sf-menu ul {
	position: absolute;
	display: none;
	top: 100%;
	left: 0;
	z-index: 99;
}
.sf-menu > li {
	float: left;
}
.sf-menu li:hover > ul,
.sf-menu li.sfHover > ul {
	display: block;
}

.sf-menu a {
	display: block;
	position: relative;
}
.sf-menu ul ul {
	top: 0;
	left: 100%;
}


.sf-menu a {
	height: 80px;
	line-height: 80px;
	margin: 20px 0;
	padding: 0 30px;
	border-right: 1px solid #ececec;
	color: $secundary-dark;
	font-weight: 400;
	text-transform: uppercase;
	
}

.sf-menu a:first-child {
	border-left: 1px solid #ececec;	
}


.sf-menu li a:hover {
	background: #ececec;
	text-decoration: none;
	}
	
.active {
	
}

.sf-menu ul {
	background: #fff;
	a {
		margin: 0 !important;
	}
}