/* ==========================================================================
   Small Screen 1024
   ========================================================================== */


@media only screen and (max-width:1030px) {
body{font-size:90%;}

.row .row .c1,.row .row .c2,.row .row .c3,.row .row .c4,.row .row .c5,.row .row .c6,.row .row .c7,.row .row .c8,.row .row .c9,.row .row .c10,.row .row .c11,.row .row .c12{width:100%;margin-bottom:20px}

.row .row > div:last-child{margin-bottom:0 !important}




}