.contactForm {
	.c12, .c6 {
		margin: 10px 0;
	}
.btn {
	margin-top: 15px;
}
	.form-group {
		width: 100%;
	}
	label {
		height: 35px;
		line-height: 35px;
		width: 100%;		
	}
	input, select {
		width: 100%;
		height: 35px;
	}
	textarea {
		width: 100%;
	}
}



.alert {
	margin-top: 60px;
	margin-bottom: 20px;
	width: 100%;
	background: #85b400;
	color: #fff;
	padding: 10px;
}



@media handheld, only screen and (max-width: 768px), only screen and (max-device-width: 768px) and (orientation:portrait){
	.form {
		.c6, .c12 {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}