/* Tablets
-------------------------------------------------------------- */

@media only screen and (max-width:500px) {

.desktop {display: none;}
.tablet {display: none;}
.mobile {display: inherit;}

body {
	text-align: center !important;
}

.call span:before {
	content: '';
}



}